import Glide, { Controls, Autoplay, Swipe } from '@glidejs/glide/dist/glide.modular.esm'

export var glideFactory = function(id) {
  if (document.getElementById(id)) {
    return new Glide(`#${id}`, {
      type: 'carousel',
      hoverpause: false,
      perView: 1,
      autoplay: false,
      animationDuration: 400
    }).mount({
      Controls,
      Autoplay,
      Swipe
    })
  }
}
